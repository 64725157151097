import React, { useEffect, useState } from "react"
// import { navigate } from "gatsby"
import Layout from "../Layout"
import styles from "./cover.module.scss"
import SvgIcon from "../SvgIcon"
import BudText from "../TextBudStage/"
import CardVideo from "../CardVideo"
import { IsMobileDevice } from "../../../utils"
import { getUserById } from "../../../services"

const Cover = () => {
  const [data, setData] = useState({})
  const [isMObile, setIsMobile] = useState()

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  })

  useEffect(() => {
    const { search } = window.location
    const id = search.split("=")[1] || []
    getUserById(id).then(setData)
  }, [])

  const handleClick = () => {
    const { search } = window.location
    const id = search.split("=")[1] || []
    return window.location.replace(`/budstage/vote/?id=${id}`)
  }

  return (
    <div className={styles.cover}>
      {!isMObile && (
        <>
          <SvgIcon
            className={styles.brewIcon}
            size="4.290vw"
            name="we-brew-icon"
          />
          <SvgIcon className={styles.textura} name="textura-fondo" />
          <BudText orientation="vertical" className={styles.budText} />
        </>
      )}

      <Layout noFooter type="secondary">
        <div className={styles.content}>
          {data && data.videoId ? (
            <CardVideo
              className={styles.coverVideoCard}
              data={data}
              toPlay
              shareType="secondary"
              handleClick={handleClick}
            />
          ) : (
            <div></div>
          )}
        </div>
      </Layout>
    </div>
  )
}

export default Cover
